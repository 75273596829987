<template>
  <v-container class="fill-height">
    <v-row align="start" justify="center">
      <v-col cols="12" md="7" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text text-center">
          EMPRESA
        </h2>

        <v-spacer></v-spacer>

        <p class="body my-5 dark white--text text-justify">
          A Autocars del Penedès som una companyia familiar de 4 generacions,
          il·lusionats i apassionats pel que fem. Amb 10 valors que considerem
          imprescindibles per la feina que fem. Tenim una actitud curiosa,
          creativa i innovadora dirigida cap a una mobilitat col·lectiva i
          equilibrada. Enfocats per aconseguir no només l’èxit de la companyia,
          sinó també l’èxit de cada persona,dins i fora d’aquesta, oferint
          solucions flexibles i socials. La nostra organització
          multidisciplinària, orgànica i líquida genera un ecosistema sostenible
          i evolutiu per la millora de les persones.Incloure les nostres tasques
          dins una acció sistèmica i al intentar recollir les sensacions
          intangibles d’usuaris i clients, ens permet ser molt propers i poder
          conèixer la realitat i necessitats dels serveis i les persones.A
          Autocars del Penedès fem de la transparència, la comunicació i la
          proximitat una forma de ser,juntament amb una condició humana híbrida
          i responsable. Positivitat per tothom. Més x Més.
        </p>

        <h4 class="white--text mt-5">Visió</h4>
        <p class="body mb-5 dark white--text">
          La llibertat de la mobilitat equilibrada i col·lectiva.
        </p>

        <h4 class="white--text mt-5">Missió</h4>
        <p class="body mb-5 dark white--text">
          Perseverar per oferir la connexió necessària per cada persona.
        </p>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-row align="start" justify="center"></v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Serveis",

  data: () => ({
    // logged: false
    showEmpreses: false,
    showParticulars: false
  })
};
</script>
